<template>
  <a-card>
    <a-form-model
      ref="search_form"
      v-bind="layout"
      :model="search_form"
      class="search-form"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="主播昵称">
            <a-input
              v-model.trim="search_form.dealer_code"
              placeholder="请输入"
              style="width: 210px"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="平台">
            <a-select
              v-model="search_form.platform_id"
              placeholder="请选择"
              show-search
              option-filter-prop="children"
              style="width: 210px"
            >
              <a-select-option
                v-for="item in platform_list"
                :key="item.id"
                :name="item.platform_name"
              >
                {{ item.platform_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="经销商名称">
            <a-input
              v-model.trim="search_form.dealer_name"
              placeholder="请输入"
              style="width: 210px"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="视频标题">
            <a-input
              v-model.trim="search_form.video_title"
              placeholder="请输入"
              style="width: 210px"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <header class="row-flex">
      <p>当前监控视频数量{{video_count|| 0}}个</p>
      <div>
        <a-button type="primary" style="margin-right: 8px" @click="searchBtn"
          >搜索</a-button
        >
        <a-button style="margin-right: 8px" icon="redo" @click="resetBtn"
          >重置</a-button
        >
      </div>
    </header>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableList"
      :loading="tableLoading"
      :pagination="false"
      :rowKey="(record, index) => index"
    >
      <div slot="authorMessage" slot-scope="text, record">
        <div class="video_message">
          <div class="img_box"><img :src="record.cover" /></div>
          <div class="message">
            <p>{{ record.title }}</p>
            <p>{{ record.nickname }}</p>
            <p>
              {{
                record.platform == 1
                  ? "抖音"
                  : record.platform == 2
                  ? "快手"
                  : "懂车帝"
              }}
            </p>
          </div>
        </div>
      </div>
      <span slot="play_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="digg_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="comment_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="share_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <div slot="publish_time" slot-scope="text, record" class="time_message">
        <span v-if='record.latest_push_time'>最近推送时间：</span>
        <span class="time" v-if='record.latest_push_time'>{{record.latest_push_time}}</span>
        <span v-if="record.aweme_mtime">数据更新时间：</span>
        <span class="time" v-if='record.aweme_mtime'>{{record.aweme_mtime}}</span>
        <span v-if="record.publish_time">发布时间：</span>
        <span class="time" v-if="record.publish_time">{{record.publish_time}}</span>
      </div>
      <div slot="ossUrl" slot-scope="text">
        <a-button type="link" @click="openVideo(text)">查看视频</a-button>
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
  </a-card>
</template>
<script>
const columns = [
  {
    title: "视频信息",
    dataIndex: "avatar",
    scopedSlots: { customRender: "authorMessage" },
    width: 270,
  },
  {
    title: "经销商",
    dataIndex: "dealer_name",
  },
  {
    title: "播放量",
    dataIndex: "play_count",
    scopedSlots: { customRender: "play_count" },
  },
  {
    title: "点赞量",
    dataIndex: "digg_count",
    scopedSlots: { customRender: "digg_count" },
  },
  {
    title: "转发量",
    dataIndex: "share_count",
    scopedSlots: { customRender: "share_count" },
  },
  {
    title: "评论量",
    dataIndex: "comment_count",
    scopedSlots: { customRender: "comment_count" },
  },
  {
    title: "时间",
    dataIndex: "publish_time",
    scopedSlots: { customRender: "publish_time" },
  },
  {
    title: "操作",
    dataIndex: "share_url",
    scopedSlots: { customRender: "ossUrl" },
    width: 70,
  },
];
export default {
  data() {
    return {
      rule_name: "",
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableList: [],
      tableLoading: false,
      columns,
      platform_list: [
        {
          platform_name: "抖音",
          id: "1",
        },
        {
          platform_name: "快手",
          id: "2",
        },
        {
          platform_name: "懂车帝",
          id: "3",
        },
      ],
      search_form: {
        dealer_code: "",
        platform_id: [],
        dealer_name: "",
        video_title: "",
      },
      video_count:''
    };
  },
  mounted() {
    this.getAuthorVideoList(1, 10);
    this.$api.core.PutAssistant.getMonitVideo().then(res=>{
        if(res.code == 0){
            this.video_count = res.data
        }
    })
  },
  methods: {
    // 获取主播列表
    getAuthorVideoList(current, page_num) {
      const { dealer_code, platform_id, dealer_name, video_title } =
        this.search_form;
      let params = {
        nickname: dealer_code,
        platform: platform_id,
        dealer_name,
        title: video_title,
        page_num: current,
        page_size: page_num,
      };
      this.tableLoading = true;
      this.$api.core.PutAssistant.getAuthorVideoList(params)
        .then((res) => {
          if (res.code == 0) {
            this.tableList = res.data.list;
            const { current, page_size, total } = res.data.pagination;
            this.pagination = {
              current,
              page_size: page_size,
              total: total,
            };
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    searchBtn() {
      this.getAuthorVideoList(1, 10);
    },
    resetBtn() {
      this.search_form = {
        dealer_code: "",
        platform_id: [],
        dealer_name: "",
        video_title: "",
      };
      this.getAuthorVideoList(1, 10);
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getAuthorVideoList(current, pageSize);
    },
    openVideo(url) {
      if (!url) {
        this.$message.warning("暂无视频播放地址");
        return;
      }
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.row-flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.video_message {
  display: flex;
  .img_box {
    width: 90px;
    height: 120px;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  .message {
    flex: 1;
    p {
      margin: 5px;
      line-height: 16px;
    }
  }
}
.time_message {
  display: flex;
  flex-flow: column;
  .time {
    color: #888;
  }
}
</style>